'use strict';

angular.module('enervexSalesappApp').controller('TemplatesCtrl', function($scope, $http, Template, $uibModal, _) {
	$scope.templates = [];
	$scope.offset = 0;
	$scope.limit = 10;
	function fetch() {
		Template.query({
			offset:$scope.offset,
			limit: $scope.limit
		}).$promise.then(function(templates){
			if (templates.length > 0) {
				$scope.templates = $scope.templates.concat(templates);
				$scope.offset = $scope.offset + $scope.limit;
				fetch();
			} else {
				$scope.templates = _.sortBy($scope.templates, function(template){
					return template.name
				})
			}
		});
	}
	fetch();


	$scope.delete = function(template) {
		Template.delete({
			id: template._id
		});
		_.each($scope.templates, function(o, i) {
			if (o === template) {
				$scope.templates.splice(i, 1);
			}
		});
	}
});
